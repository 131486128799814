<template>
  <div class="table-responsive adaptive-table sentMessageTable" v-if="list.length > 0">
    <table class="table table-striped table-hover " style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id">
          <td :data-label="$t('lookupContact')">
            {{ row.contact }}
          </td>
          <td :data-label="$t('status')">
            {{ row.status }}&ensp;<button v-if="row.status === 'completed'" class="btn btn-sm btn-primary" @click.prevent="prepareInfo(row)">{{ $t('details') }}</button>
          </td>
          <td :data-label="$t('facetime')">
            <span v-if="row.result_v1 && !row.result_v1.apple_services.facetime.status">-</span>
            <span v-else-if="row.status === 'completed' && row.result_v1.apple_services.facetime.status === 'available'">✅ {{ humanDate(row.result_v1.apple_services.facetime.date, $i18n.locale)}}</span>
            <span v-else-if="row.status === 'completed' && row.result_v1.apple_services.facetime.status !== 'available'">🤷 {{ $t('unknown') }}</span>
          </td>
          <td :data-label="$t('imessage')">
            <span v-if="row.result_v1 && !row.result_v1.apple_services.imessage.status">-</span>
            <span v-else-if="row.status === 'completed' && row.result_v1.apple_services.imessage.status === 'available'">✅ {{ humanDate(row.result_v1.apple_services.imessage.date, $i18n.locale)}}</span>
            <span v-else-if="row.status === 'completed' && row.result_v1.apple_services.imessage.status !== 'available'">❌ -</span>
          </td>
          <td :data-label="$t('date')">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'

export default {
  name: 'LookupHistoryTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { humanDateTime, humanDate } = useHumanDate()
    const i18n = useI18n()
    const { openModal } = useTableHandlers()

    const fields = ['recipient', 'status', 'facetime', 'imessage', 'date']
    const prepareInfo = (info) => {
      const result = info.result_v1
      const apple_services = result.apple_services
      const obj = {
        facetime: apple_services.facetime.status || '-',
        facetime_last_check: apple_services.facetime.date ? humanDateTime(apple_services.facetime.date, i18n.locale) : '-',
        facetime_audio_html: (apple_services.facetime.links && apple_services.facetime.links.facetime_audio) ? `<a href="${apple_services.facetime.links.facetime_audio}">${apple_services.facetime.links.facetime_audio}</a>` : '-',
        imessage: apple_services.imessage.status || '-',
        imessage_last_check: apple_services.imessage.date ? humanDateTime(apple_services.imessage.date, i18n.locale) : '-',
        imessage_sms_html: (apple_services.imessage.links && apple_services.imessage.links.sms) ? `<a href="${apple_services.imessage.links.sms}">${apple_services.imessage.links.sms}</a>` : '-',
        carrier: {
          title: 'carrier',
          children: {
            carrier: (result.carrier && result.carrier.carrier) || '-',
            carrier_number_type: (result.carrier && result.carrier.number_type) || '-',
          },
        },
        country: {
          title: 'country',
          children: {
            country_name: (result.country && result.country.name) || '-',
            country_flag: (result.country && result.country.flag) || '-',
            country_iso2: (result.country && result.country.iso2) || '-',
            country_iso3: (result.country && result.country.iso3) || '-',
            country_numeric: (result.country && result.country.numeric) || '-',
          },
        },
        formats: {
          title: 'formats',
          children: {
            formats_e164: (result.format && result.format.e164) || '-',
            formats_international: (result.format && result.format.international) || '-',
            formats_national: (result.format && result.format.national) || '-',
            formats_rfc3966: (result.format && result.format.rfc3966) || '-',
          },
        },
        currencies: result.currencies && result.currencies.join(', '),
        timezones: result.time_zones && result.time_zones.join(', '),
      }
      openModal(obj, 'popupLookupDetails')
    }
    return {
      humanDateTime,
      humanDate,
      prepareInfo,
      fields,
    }
  },
}
</script>
